import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './alert.scss';

const COMPONENT_CLASS = 'alert';
const ALERT_TYPE_INFO = 'info';

const Alert = ({ children, type, showIcon, className }) => {
    const typeClass = type ? `${COMPONENT_CLASS}--${type}` : null;

    const classes = classNames('d-flex flex-row', COMPONENT_CLASS, typeClass, className, {
        'no-icon': !showIcon
    });

    return (
        <article className={classes}>
            <div className={`${COMPONENT_CLASS}__icon`} />
            <div className={`${COMPONENT_CLASS}__text`}>
                {children}
            </div>
        </article>
    );
};

Alert.proTypes= {
    type: PropTypes.string,
    showIcon: PropTypes.bool,
    className: PropTypes.string,
};

Alert.defaultProps = {
    type: ALERT_TYPE_INFO,
    showIcon: true,
};

export {
    Alert,
    ALERT_TYPE_INFO,
};
