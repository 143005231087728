import React, { useLayoutEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Trans } from 'gatsby-plugin-react-i18next';
import { Alert } from '@molecules/alert';
import { Gradient } from '@utils/Gradient';

import './hero.scss';

const COMPONENT_CLASS = 'hero';
const HERO_DESCRIPTION_BOTTOM = 'bottom';
const HERO_DESCRIPTION_RIGHT = 'right';

const Hero = ({
  title,
  subtitle,
  description,
  info,
  infoIcon,
  descriptionPosition,
  className,
  descriptionClassName,
  caveat,
  caveatIcon,
  meshGradient,
}) => {
    const components = {
      strong: <strong />,
      u: <u />,
      i: <i />,
      p: <p />,
      br: <br />,
      span: <span />,
      // eslint-disable-next-line
      a: <a />,
    };
    const classes = classNames(
        COMPONENT_CLASS,
        `${COMPONENT_CLASS}--${descriptionPosition}`,
        {
            [`${COMPONENT_CLASS}--${className}`]: className,
        },
    );
    const bottomDescription = descriptionPosition === HERO_DESCRIPTION_BOTTOM;

    const MESH_GRADIENT_CONTAINER = `${COMPONENT_CLASS}__mesh`;
    const meshGradientContainerRef = React.createRef();
    const descriptionElement = description ?
    (

        <article className={`${COMPONENT_CLASS}__description`}>
            <Trans components={components}>{description}</Trans>
        </article>
    ) : null;

    const infoElement = info && <Alert showIcon={infoIcon} className={'info'}><Trans components={components}>{info}</Trans></Alert>;
    const caveatElement = caveat && <Alert showIcon={caveatIcon}><Trans components={components}>{caveat}</Trans></Alert>;
    const infoClasses = classNames('d-flex h-100', {
        'align-items-end' : infoElement && !caveatElement,
        'flex-column justify-content-between' : infoElement && caveatElement,
    });

    useLayoutEffect(() => {
        const container = meshGradientContainerRef.current;
        if (meshGradient && container) {
            const gradient = new Gradient();
            gradient.initGradient(`#${MESH_GRADIENT_CONTAINER}`);
        }
    }, [meshGradient, meshGradientContainerRef, MESH_GRADIENT_CONTAINER]);

    return (
        <section className={classes}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        {
                            subtitle ?
                                (
                                    <h3 className={`${COMPONENT_CLASS}__subtitle font-weight-bold`}>
                                        <Trans components={components}>{subtitle}</Trans>
                                    </h3>
                                ) : null
                        }
                        <h1 className={`${COMPONENT_CLASS}__title`}><Trans components={{ span: <span /> }} >{title}</Trans></h1>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className={`col-lg-7 ${descriptionClassName}`}>
                        { bottomDescription ? descriptionElement : null}
                    </div>
                    <div className="col-lg-5 pl-md-4 pl-lg-5">
                        { !bottomDescription ? descriptionElement : null}
                        {
                            (infoElement || caveatElement) && <div className={infoClasses}>{ caveatElement } { infoElement }</div>
                        }
                    </div>
                </div>
            </div>
            { meshGradient && <canvas id={MESH_GRADIENT_CONTAINER} ref={meshGradientContainerRef} className="mesh-gradient" />}
            { meshGradient && <div className="bg-gradient" /> }
        </section>
    );
};

Hero.proTypes= {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    info: PropTypes.string,
    infoIcon: PropTypes.bool,
    caveat: PropTypes.string,
    caveatIcon: PropTypes.bool,
    descriptionPosition: PropTypes.string,
    className: PropTypes.string,
    descriptionClassName: PropTypes.string,
    meshGradient: PropTypes.bool,
};

Hero.defaultProps = {
    subtitle: '',
    description: '',
    info: '',
    descriptionPosition: HERO_DESCRIPTION_BOTTOM,
    className: '',
    descriptionClassName: '',
    meshGradient: false,
    infoIcon: true,
};

export {
    Hero,
    HERO_DESCRIPTION_BOTTOM,
    HERO_DESCRIPTION_RIGHT,
};
